import { get, post, put } from "@/application/api";
import { AxiosResponse } from "axios";
import { UnitLoad } from "@/modules/delivery/enums";

export interface DeliveryProduct {
  articleNumber: string;
  quantity: number;
  unit: string;
  units?: {
    name: string;
  }[];
}

export interface DeliveryEnteredBy {
  name: string;
  email: string;
}

export interface DeliverySingle {
  stockStatus: number;
  reference: string;
  plannedDate: string;
  unitLoad: UnitLoad;
  unitLoadInfo: number;
  enteredBy: DeliveryEnteredBy;
  items: DeliveryProduct[];
}

export const conceptSave = ({
  delivery,
  clientId,
}: {
  delivery: Partial<DeliverySingle>;
  clientId: number;
}): Promise<AxiosResponse> =>
  post(`client/${clientId}/delivery/concept`, delivery);

export const conceptSaveById = ({
  deliveryId,
  delivery,
  clientId,
}: {
  delivery: Partial<DeliverySingle>;
  deliveryId: number;
  clientId: number;
}): Promise<AxiosResponse> =>
  put(`client/${clientId}/delivery/concept/${deliveryId}`, delivery);

export const send = ({
  delivery,
  clientId,
}: {
  delivery: Partial<DeliverySingle>;
  clientId: number;
}): Promise<AxiosResponse> => {
  return post(`client/${clientId}/delivery`, delivery);
};

export const sendById = ({
  deliveryId,
  delivery,
  clientId,
}: {
  delivery: Partial<DeliverySingle>;
  deliveryId: number;
  clientId: number;
}): Promise<AxiosResponse> => {
  return post(`client/${clientId}/delivery/concept/${deliveryId}`, delivery);
};

export const conceptDeliveryShow = ({
  clientId,
  deliveryId,
}: {
  clientId: number;
  deliveryId: number;
}): Promise<
  AxiosResponse<{
    data: DeliverySingle;
    isManual?: boolean;
    validationErrors: { message: string; errors: Record<string, string[]> };
  }>
> => get(`client/${clientId}/delivery/concept/${deliveryId}`);
